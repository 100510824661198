import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Link from "next/link";
import Image from "next/image";

import { Device } from "@/utils/device";
import { CM, CS } from "../Typography";

type FeaturedNavContentProps = {
  featuredItems: {
    copy: string;
    href: string;
    image: {
      alt: string;
      src: string;
    };
  }[];
};

const FeaturedNavContent: FunctionComponent<FeaturedNavContentProps> = (
  props
) => {
  if (props.featuredItems.length === 0) return null;
  return (
    <S.Container>
      <CM weight="700">Featured</CM>
      <S.LinksContainer>
        {props.featuredItems.map((item) => (
          <>
            <Link href={item.href} key={item.href}>
              <S.FeatureBlock tabIndex={0}>
                <S.ImageContainer>
                  <Image
                    src={item.image.src}
                    alt={item.image.alt}
                    layout="fill"
                    objectFit="cover"
                  />
                </S.ImageContainer>
                <CS>{item.copy}</CS>
              </S.FeatureBlock>
            </Link>
          </>
        ))}
      </S.LinksContainer>
    </S.Container>
  );
};

type StyleProps = {
  dropdownOpen?: boolean;
};

const S = () => {};

S.Container = styled.div<StyleProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

S.LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  @media (min-width: ${Device.desktop}) {
    flex-direction: row;
  }
`;

S.FeatureBlock = styled.div`
  max-width: 20em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    transform: scale(1.02);
  }

  &:focus-visible {
    outline: solid blue 2px;
  }
`;

S.ImageContainer = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px #c3afa1;
  border-radius: 0.1em;
  width: 100%;
  flex: none;
  flex-shrink: 0;
  overflow: hidden;
  aspect-ratio: 2.2/1;

  @supports not (aspect-ratio: 2.2/1) {
    &::before {
      float: left;
      padding-top: 45%;
      content: "";
    }
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
`;

export default FeaturedNavContent;
