import React from "react";
import styled from "styled-components";
import Link from "next/link";
import * as Router from "next/router";

import { colours } from "@/utils";
import { Device } from "@/utils/device";
import { CS } from "../Typography";
import { NavigationItemModel } from "@/types/NavigationItems";
import { Triangle } from "../Icons";
import FeaturedNavContent from "./FeaturedNavContent";

interface NavDropdownContentProps extends NavigationItemModel {
  clearDropdowns: () => void;
  dropdownOpen: boolean;
}

const NavDropdownContent = React.forwardRef<
  HTMLDivElement,
  NavDropdownContentProps
>((props, ref) => {
  const router = Router.useRouter();

  return (
    <S.Container ref={ref}>
      <S.LinksContainer>
        {props.links?.map((link) => (
          <Link href={link.path} key={link.title}>
            <S.LinkButton
              tabIndex={props.dropdownOpen ? 0 : -1}
              onClick={() => {
                props.clearDropdowns();
                router.push(link.path);
              }}
              aria-label={`Visit ${link.title} page`}
            >
              <Triangle colour={colours.charcoal} />
              &nbsp;<S.LinkText>{link.title}</S.LinkText>
            </S.LinkButton>
          </Link>
        ))}
      </S.LinksContainer>
      {!!props.content && <FeaturedNavContent featuredItems={props.content} />}
    </S.Container>
  );
});

type StyleProps = {
  dropdownOpen?: boolean;
};

const S = () => {};

S.Container = styled.div<StyleProps>`
  width: 100%;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media (min-width: ${Device.desktop}) {
    flex-direction: row;
    gap: 2em;
  }
`;

S.LinksContainer = styled.div`
  flex: none;
  display: flex;
  flex-direction: column;

  > p {
    margin: 0 0 0.5em 0;
  }

  @media (min-width: ${Device.desktop}) {
    width: 240px;
    > p {
      margin: 0 0 1em 0;
      padding: 0 1.5em;
    }
  }
`;

S.LinkButton = styled.button`
  position: relative;
  outline: inherit;
  border: none;
  height: 100%;
  width: fit-content;
  background: none;
  cursor: pointer;
  padding: 0.5em 0;
  flex: 0;
  display: flex;
  align-items: center;
  color: inherit;
  font: inherit;

  &:hover {
    p:after {
      background-color: ${colours.charcoal};
      width: 100%;
    }
  }

  &:focus-visible {
    outline: 2px solid blue;
  }

  @media (min-width: ${Device.mobile}) {
    padding: 0.8em;
  }

  @media (min-width: ${Device.desktop}) {
    padding: 0.5em 1.5em;
  }
`;

S.LinkText = styled(CS)`
  position: relative;
  width: fit-content;
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
  font-weight: 700;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 0;
    left: 0;
    bottom: -0.15em;
    height: 0.1em;
    background-color: ${colours.charcoal};
    transition: all 0.3s ease-out;
  }

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -0.15em;
    height: 0.1em;
    background-color: ${colours.shellGuideline};
    transition: all 0.3s ease-out;
  }

  &:hover::after {
    width: 100%;
  }
`;

export default NavDropdownContent;
