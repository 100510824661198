import axios from "axios";
import promiseRetry from "promise-retry";
import * as Sentry from "@sentry/browser";

import firebase from "../firebase.client";
import config from "../config.client";

const bypassUrls = [
  "/api/email.validate",
  "/api/user.subscribe-to-newsletter",
  "/api/user.contact-allbright",
  "/api/user.logout",
  "/api/nft.check-whitelist",
  "/api/contentful.get-banner-by-page",
  "/api/stripe.get-price-by-id",
  "/api/stripe.get-promotion-by-code",
  "/api/stripe.create-payment-intent",
  "/api/voucher.create",
  "/api/voucher.validate",
  "/api/user/create-alliance",
  config.CAT_BASE,
  config.ALLBRIGHT_API_URL + "/v1/crm/customer",
  config.ALLBRIGHT_API_URL + "/v1/crm/program/enroll",
  config.ALLBRIGHT_API_URL + "/v1/crm/customer/subscribe",
  config.ALLBRIGHT_API_URL + "/v1/auth/register/",
  config.ALLBRIGHT_API_URL + "/v1/vouchers/create",
  config.ALLBRIGHT_API_URL + "/v1/vouchers/validate",
  config.ALLBRIGHT_API_URL + "/v1/vouchers/get-price",
  config.ZAPIER_URL,
  config.CONTENTFUL_GENERAL,
];

if (process.browser) {
  axios.interceptors.request.use(async (request) => {
    if (bypassUrls.some((url) => request.url.startsWith(url))) return request;

    const idToken = await promiseRetry({ retries: 10 }, async (retry) => {
      try {
        const token = await firebase.auth().currentUser?.getIdToken();
        if (token) return token;
      } catch (err) {
        Sentry.captureException(err);
        return undefined;
      }
      retry();
    });

    request.headers.authorization = `Bearer ${idToken}`;
    return request;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        window.location = "/auth/session-expired";
      } else {
        console.info(
          "axios interceptor - error with no response object",
          error
        );
        if (!error) {
          Sentry.addBreadcrumb({
            category: "auth",
            message: "axios interceptor - error butt no error",
            level: "info",
          });
        }

        return Promise.reject(error);
      }
    }
  );
}
