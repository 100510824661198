"use strict";

let isProd = process.env.NODE_ENV === "production";
if (process.env.NEXT_PUBLIC_BACKEND) {
  isProd = process.env.NEXT_PUBLIC_BACKEND === "prod";
}

module.exports = {
  IS_PROD: isProd,

  /* The AllBright Firebase auth user ID */
  ALLBRIGHT: "f6effedad55debd98fbdc801cf03a407",
  ALLBRIGHT_URL: isProd
    ? "https://www.allbrightcollective.com"
    : "http://localhost:3000",

  ALLBRIGHT_API_URL: isProd
    ? "https://api.allbrightcollective.com"
    : "https://allbright-api-staging.vercel.app",
  ALLBRIGHT_API_KEY: isProd
    ? "f3cd8308b509481c3fd75951cc22ef6ee40487f2c4b139b64e320facc26de59f"
    : "0694027cb1b6139ffc2bc739df03318293bd9dbb1ca6ba6ee652d9bafe08d47c",

  BASE_PATH: isProd ? "" : "http://localhost:3000",

  CONTENTFUL_GENERAL:
    "https://graphql.contentful.com/content/v1/spaces/r1aimdyec1vl/environments/main?access_token=JH2dxC1EzLBlFHNJog95K65RMo6QFU5ZNpTRWZfCxrU",
  CONTENTFUL_GENERAL_PREVIEW:
    "https://graphql.contentful.com/content/v1/spaces/r1aimdyec1vl/environments/main?access_token=93QHSA7ZZ5QegycGhMPolY4ljNBeMwAB7XV_fYh-4ag",
  CONTENTFUL_EDIT:
    "https://graphql.contentful.com/content/v1/spaces/2r3ks6xyfvix/environments/master?access_token=A-jQz2bD-JqY0ON7Dr2wgM5kJDO2QdZqWQNEDxsDPqI",
  CONTENTFUL_EDIT_PREVIEW:
    "https://graphql.contentful.com/content/v1/spaces/2r3ks6xyfvix/environments/master?access_token=xI3-SlzJfmOVqadckpwG69y2HhX226W-POraRImkkrg",

  ZAPIER_URL: isProd
    ? "https://hooks.zapier.com/hooks/catch"
    : "https://hooks.zapier.com/hooks/catch",

  GA_TRACKING_ID: "UA-158779384-1",
  FB_TRACKING_ID: "243014206938722",

  CAT_BASE: "https://api.revenuecat.com/v1/subscribers",
  CAT_PUBLIC_KEY: "FXYprPgMuzuzcFITzgQqPeMYOfyvxdCH",

  STRIPE_PUBLIC: process.env.NEXT_PUBLIC_STRIPE_PUBLIC,
  CLUB_PRODUCT_ID: isProd ? "prod_JQdkCdlU7pOZE4" : "prod_JQG62PFNbAbrMR",
  DIGITAL_PRODUCT_ID: isProd ? "prod_JQddYb782xnU9o" : "prod_IrqSF0cmCS7dEI",
  DIGITAL_GIFT_PRICE_ID: isProd
    ? "price_1OPO2QGPF9omVU3Se3Gn3cL7"
    : "price_1OPOBWGPF9omVU3SZybb9iWT",
  RUDDERSTACK_DATA_PLANE_URL: "https://tasmansyszhb.dataplane.rudderstack.com",

  STREAM_PUBLIC: isProd ? "3suahg46tpm9" : "7ywxk55ygrpq",
  STREAM_APP_ID: isProd ? "1117468" : "1124042",

  GTM_ID: "GTM-TFQFC5V",

  DOT_DIGITAL: isProd
    ? {
        PROGRAMS: {
          newsletter: 333985,
        },
        ADDRESS_BOOKS: {
          alliance_newsletter: {
            id: 28793917,
            name: "Newsletter AllBright Alliance",
          },
          elevate_brochure: { id: 42073735, name: "Elevate Download Brochure" },
          elevate_waitlist_nyc: { id: 42138400, name: "Elevate Waitlist NYC" },
          elevate_waitlist_virtual: {
            id: 43075063,
            name: "Elevate Virtual",
          },
        },
      }
    : {
        PROGRAMS: {
          newsletter: 338380,
        },
        ADDRESS_BOOKS: {
          alliance_newsletter: {
            id: 32666104,
            name: "Newsletter AllBright Alliance",
          },
          elevate_brochure: { id: 32666104, name: "Elevate Download Brochure" },
          elevate_waitlist_nyc: { id: 32666104, name: "Elevate Waitlist NYC" },
          elevate_waitlist_virtual: {
            id: 43262692,
            name: "Elevate Virtual",
          },
        },
      },

  ALGOLIA_SEARCH: isProd
    ? {
        APP_ID: "SWQPUFSE9H",
        PUBLIC_API_KEY: "c439f52c41dd16f7a3a0b939b9e51e27",
        CONNECT_INDEX: "allbright_web",
        SITE_WIDE_INDEX: "allbright_web_site_wide",
      }
    : {
        APP_ID: "SWQPUFSE9H",
        PUBLIC_API_KEY: "f80b598bf8bf07808ade1a85d1e2de2f",
        CONNECT_INDEX: "staging_allbright_web",
        SITE_WIDE_INDEX: "allbright_web_site_wide",
      },

  FIREBASE_CLIENT: isProd
    ? {
        apiKey: "AIzaSyA5knxokt6MN24qqLpdsUJd4lGf_YX7I2s",
        authDomain: "allbright-496e8.firebaseapp.com",
        projectId: "allbright-496e8",
        storageBucket: "allbright-496e8.appspot.com",
        messagingSenderId: "812776786652",
        appId: "1:812776786652:web:3157ebdd6527bbd09e8219",
      }
    : {
        apiKey: "AIzaSyAZLP-o1Qy5Yjmttu5XQVbA1jtle7CYAkk",
        authDomain: "allbright-dev.firebaseapp.com",
        projectId: "allbright-dev",
        storageBucket: "allbright-dev.appspot.com",
        messagingSenderId: "627789850522",
        appId: "1:627789850522:web:b23153f835f77b70393c23",
      },

  MENTOR_ARTICLES: ["3CIyTTHnXYzEF5OV34gfGf", "7xG3uXWFtrOEYgkCm4QWue"],

  MARKETPLACE_GROUP_ID: isProd
    ? "58b725f7-2bd1-a11a-8a14-1a119dac12d9"
    : "f66db43a-6169-d0de-fe11-6e8020d8e67f",
};
